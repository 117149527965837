import React from 'react'

function ServerMessage(props) {
    const { text, date, stack } = props

    return (
        <div className="chat-message chat-message_server">
            {!stack && <div className="timestamp">{date}</div>}
            <div className="content" dangerouslySetInnerHTML={{ __html: text }} />
        </div>
    )
}

export default ServerMessage
