import React, { useEffect, useRef } from 'react'

function NinInput(props) {
    const { onSubmit, onChange, value } = props
    const inputRef = useRef(null)
    const cursorPositionRef = useRef(0)

    const handleKeyDown = async (event) => {
        if (event.keyCode === 13) {
            onSubmit()
        }

        if (event.ctrlKey && event.keyCode === 86) {
            return
        }

        if (/^[a-zA-Z\s`~!@#$%^&*()_={}\[\]:;"'<>,?\\|+ľščťžýáíé='úä§ňô§\-.]*$/u.test(event.key) && !event.metaKey && ![8, 9, 37, 38, 39, 40, 46].includes(event.keyCode)) {
            event.preventDefault()
        }
    }

    const handlePaste = (event) => {
        event.preventDefault()
        const clipboardData = event.clipboardData
        const pastedText = clipboardData?.getData('text')
        const newText = value.slice(0, event.target.selectionStart) + pastedText + value.slice(event.target.selectionEnd)
        handleNinChange({ target: { value: newText } })
    }

    const handleNinChange = (event) => {
        const { value, selectionStart } = event.target
        let newValue = value.replace(/[^0-9/]/g, '')
        let newCursorPosition = selectionStart

        const slashPosition = newValue.indexOf('/')
        if (slashPosition !== 6 && slashPosition !== -1) {
            newValue = newValue.replace(/\//g, '')
            if (selectionStart > slashPosition) {
                newCursorPosition--
            }
        }

        if (newValue.length > 6 && newValue.indexOf('/') === -1) {
            newValue = newValue.substring(0, 6) + '/' + newValue.substring(6)
            if (selectionStart > 6) {
                newCursorPosition++
            }
        }

        if (newValue.length > 10) {
            newValue = newValue.slice(0, 11)
        }

        cursorPositionRef.current = newCursorPosition
        onChange(newValue)
    }

    useEffect(() => {
        inputRef.current?.setSelectionRange(cursorPositionRef.current, cursorPositionRef.current)
    }, [value])

    return <input ref={inputRef} onPaste={handlePaste} onKeyDown={handleKeyDown} onChange={handleNinChange} value={value} className="form-control form-input form-input_auth mb-1" name="nin" id="nin" type="text" placeholder="112233/0000" />
}

export default NinInput
