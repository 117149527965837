import React from 'react'
import EventBus from './Shared/EventBus'
import chevronIcon from '../../images/chevron.svg'
import closeIcon from '../../images/close.svg'
import operatorImg from '../../images/operator.png'
import { settings } from '../../../../../FrontendBundle/Resources/Assets/js/config'

function ChatHeader() {
    const deactivateChat = () => EventBus.emit('chat-close')
    const resolveChat = () => EventBus.emit('form-open', 'resolveChat')

    return (
        <div className="chat-header">
            <div className="header__content">
                <img className="header__image" src={settings.assets() + operatorImg} alt="operator-icon" />
                <p className="header__name">Kateřina</p>

                <div onClick={deactivateChat} className="header__deactivate">
                    <img src={settings.assets() + chevronIcon} alt="chevron" />
                </div>
                <div onClick={resolveChat} className="header__resolve">
                    <img src={settings.assets() + closeIcon} alt="chevron" />
                </div>
            </div>
            <div className="status-bar">
                <p className="status-bar__info">Jsme online a odpovídáme okamžitě</p>
            </div>
        </div>
    )
}

export default ChatHeader
