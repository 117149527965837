import { useState } from 'react'
import EventBus from '../../Shared/EventBus'
import Socket from './Connection/useCommunication'
import axios from 'axios'
import IncomingMessage from './Connection/IncomingMessage'
import { getCookie, removeCookie, setCookie, SetSuggestionHelperCookie } from '../../Shared/Helpers'

const UseChat = () => {
    const [initialized, setInitialized] = useState(false)
    const [chatUuid, setChatUuid] = useState(null)
    const [active, setActive] = useState(false)
    const [serverTyping, setServerTyping] = useState(false)
    const [messages, setMessages] = useState([])
    const [activeForm, setActiveForm] = useState(null)
    const [preventInput, setPreventInput] = useState(false)
    const [newMessage, setNewMessage] = useState(false)
    const [runningConversationSuggestion, setRunningConversationSuggestion] = useState(true)
    const [feedbackSubmitMessage, setFeedbackSubmitMessage] = useState(null)

    const init = async () => {
        // Chat events
        EventBus.on('chat-open', handleChatOpen)
        EventBus.on('chat-close', () => {
            setActive(false)
            setNewMessage(false)
            setRunningConversationSuggestion(false)
        })
        EventBus.on('chat-resolve', handleChatResolve)
        EventBus.on('form-close', handleFormClose)
        EventBus.on('form-open', (form) => setActiveForm(form))
        EventBus.on('send-message', sendMessage)
        EventBus.on('send-feedback', (data) => sendFeedback(data))
        EventBus.on('send-rating-feedback', (feedback) => {
            sendRatingFeedback(feedback)
        })

        // Socket events
        EventBus.on('initialize-message', handleInitializeMessage)
        EventBus.on('typing-message', handleTypingMessage)
        EventBus.on('start-client-typing', handleStartClientTyping)
        EventBus.on('client-message', handleClientMessage)
        EventBus.on('operator-message', handleOperatorMessage)
        EventBus.on('system-message', handleSystemMessage)
        EventBus.on('action-message', handleActionMessage)

        if (getCookie('chatUuid')) {
            // Don't show help suggestion for a while.
            SetSuggestionHelperCookie()
            await connect()
        }

        setInitialized(true)
    }

    const deinit = () => {
        EventBus.emit('chat-close')

        EventBus.remove('chat-close')
        EventBus.remove('chat-resolve')
        EventBus.remove('chat-open')
        EventBus.remove('form-close')
        EventBus.remove('form-open')
        EventBus.remove('send-message')
        EventBus.remove('initialize-message')
        EventBus.remove('typing-message')
        EventBus.remove('start-client')
        EventBus.remove('client-message')
        EventBus.remove('operator-message')
        EventBus.remove('system-message')
        EventBus.remove('action-message')
        EventBus.remove('authenticationSuccess')
        EventBus.remove('send-feedback')
        EventBus.remove('send-rating-feedback')

        setChatUuid(null)
        setMessages([])
        setActiveForm(null)
        setServerTyping(false)
        setActive(false)
        setInitialized(false)
        Socket.disconnect()

        removeCookie('chatUuid')
    }

    const sendRatingFeedback = async (feedback) => {
        const uuid = getCookie('chatUuid')

        await axios
            .post(`/chat/client/feedback/${uuid}`, {
                clientFeedback: feedback,
            })
            .then(() => {
                setFeedbackSubmitMessage('Děkujeme za Vaše hodnocení')
            })
            .catch((err) => {
                setFeedbackSubmitMessage('Nastala chyba a Vaše hodnocení se bohužel nepodařilo odeslat')
                console.warn(err)
            })
    }

    const connect = async () => {
        const uuid = getCookie('chatUuid')

        if (uuid) {
            await axios({
                method: 'GET',
                url: `/chat/client/${uuid}`,
            })
                .then((response) => {
                    if (response.data.data.chat.messages) {
                        response.data.data.chat.messages.map((message) => {
                            setMessages((messages) => [...messages, new IncomingMessage(message)])
                        })
                    }
                })
                .then(() => {
                    Socket.start(getCookie('chatUuid'), getCookie('chash'))
                })

            return
        }

        Socket.start(getCookie('chatUuid'), getCookie('chash'))
    }

    const handleChatOpen = async () => {
        setChatUuid((uuid) => {
            if (uuid === null) {
                connect()
            }

            setActive(true)
            return uuid
        })

        setNewMessage(false)
    }

    const handleFormClose = (form = null) => {
        setActiveForm((active) => {
            if (active === form || form === null) {
                return null
            }

            return active
        })
    }

    const handleInitializeMessage = (message) => {
        setChatUuid(message.uuid)

        let date = new Date()
        date.setTime(date.getTime() + 24 * 60 * 60 * 1000)
        setCookie('chatUuid', message.uuid, date.toUTCString())
        Socket.setUuid(message.uuid)
    }

    const handleClientMessage = (message) => {
        //Meaning that communication is set to chatbot which prevent client from writing more than one message at once
        if (message.preventInput) {
            handleTypingMessage(false)
            setPreventInput(true)
        }

        setMessages((messages) => [...messages, message])
    }

    const handleOperatorMessage = (message) => {
        handleStopTyping()
        setPreventInput(false)
        setMessages((messages) => [...messages, message])

        if (!active) {
            setNewMessage(true)
        }
    }

    const handleSystemMessage = (message) => {
        handleStopTyping()
        setPreventInput(false)
        setMessages((messages) => [...messages, message])
    }

    const handleActionMessage = (message) => {
        switch (message.action) {
            case 'clientAuthenticate':
                handleAuthentication(true)
                break
            case 'clientAuthenticateSuccess':
                EventBus.emit('authenticationSuccess')
                break
            case 'clientResolveChat':
                handleChatResolve(true)
                break
            case 'noOperatorAvailable':
                handleNoOperatorAvailable(true)
                break
            case 'operatorSendLoginLink':
                handleSendLoginLink(true)
                break
            default:
                console.log('There is no resolver for this message')
        }
    }

    const sendMessage = (text) => {
        Socket.send(text)
    }

    const sendFeedback = (data) => {
        const { action, result } = data
        Socket.sendFeedback(action, result)
    }

    const handleStartClientTyping = () => {
        Socket.sendTyping()
    }

    let serverStopTypingTimeout = null
    const handleTypingMessage = (autoStop = true) => {
        setServerTyping(true)

        if (serverStopTypingTimeout !== null) {
            clearTimeout(serverStopTypingTimeout)
        }

        if (autoStop) {
            serverStopTypingTimeout = setTimeout(handleStopTyping, 2500)
        }
    }

    const handleStopTyping = () => {
        clearTimeout(serverStopTypingTimeout)
        setServerTyping(false)
    }

    const handleAuthentication = () => {
        EventBus.emit('form-open', 'clientAuthenticate')
    }

    const handleSendLoginLink = () => {
        // EventBus.emit('operatorSendLoginLink') // There is no listener for this event yet
    }

    const handleChatResolve = (allowed = false) => {
        Socket.resolve()

        if (allowed) {
            EventBus.emit('chat-deinit')
        }

        setNewMessage(false)
    }

    const handleNoOperatorAvailable = () => {
        EventBus.emit('form-open', 'outOfService')
    }

    return {
        init,
        deinit,
        initialized,
        chatUuid,
        active,
        newMessage,
        runningConversationSuggestion,
        serverTyping,
        messages,
        activeForm,
        preventInput,
        feedbackSubmitMessage,
    }
}

export default UseChat
