import React, { useRef, useState } from 'react'
import Lottie from 'lottie-react'
import axios from 'axios'
import moment from 'moment/moment'
import SubmitButton from '../ChatMessage/SubmitButton'
import callAnimation from '../../../images/call.json'
import okAnimation from '../../../images/ok.json'
import { config, settings } from '../../../../../../FrontendBundle/Resources/Assets/js/config'

function OutOfServiceForm() {
    const submitAnimation = useRef(null)
    const successAnimation = useRef(null)

    const [phoneNumber, setPhoneNumber] = useState('')
    const [formLoading, setFormLoading] = useState(false)
    const [formSuccess, setFormSuccess] = useState(false)
    const [formError, setFormError] = useState(false)

    const handleFormSubmit = () => {
        setFormLoading(true)
        const phoneNumberToSubmit = phoneNumber.replace(/\s/g, '')

        axios.post('/call-me-back', { phone: phoneNumberToSubmit }).then((response) => {
            if (response.data.accepted === true) {
                setFormSuccess(true)
                successAnimation.current.play()
                setFormLoading(false)
            } else {
                submitAnimation.current.goToAndPlay(0)
                setFormError(true)
                setFormLoading(false)
            }
        })
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault()
            handleFormSubmit()
        }

        if ((!/^[0-9]$/.test(event.key) && event.keyCode > 47) || event.keyCode === 32) {
            event.preventDefault()
        }

        if (phoneNumber.length > 10 && (event.keyCode > 47 || event.keyCode === 32)) {
            event.preventDefault()
        }

        setFormError(false)
    }

    const handlePhoneNumberChange = (event) => {
        const phoneNumberToDisplay = event.target.value?.replace(/(\d{3})(?=\d)/g, '$1 ')
        setPhoneNumber(phoneNumberToDisplay)
    }

    const frameEnter = (data) => {
        const frame = Math.floor(data.currentTime * 100) / 100

        if (frame > 56 && frame < 26.75) submitAnimation.current.pause()
    }

    const outOfServiceInfo = () => {
        const currentTime = moment()

        if (currentTime.isBetween(config.workHoursFrom, config.workHoursTo)) {
            return `<div class="mb-3">Omlouváme se, chat momentálně není dostupný.</div>
                    <div class="mb-4">
                        Zadejte vaše telefonní číslo,<br />
                        ozveme se Vám.
                    </div>`
        }

        return `<div class="mb-3"> Omlouváme se, chat není mimo pracovní dobu k dispozici.</div>
                <div class="mb-3">
                    Kontaktovat nás prostřednictvím chatu můžete:<br />
                    v pracovní dny mezi
                    ${config.workHoursFrom.format('HH:mm')}&nbsp;-&nbsp;${config.workHoursTo.format('HH:mm')}<br />
                    nebo o víkendu mezi
                    ${config.weekendWorkHoursFrom.format('HH:mm')}&nbsp;-&nbsp;${config.weekendWorkHoursTo.format('HH:mm')}
                </div>
                <div class="mb-4">nebo zadejte vaše tel. číslo a my se Vám ozveme.</div>`
    }

    return (
        <>
            <div className="form__content">
                <div className="content-wrapper">
                    <div className="image-wrapper">
                        <div className={`out-of-service-image ${formSuccess ? 'success' : ''} ${formError ? 'error' : ''}`}>
                            <div className="call">
                                <Lottie animationData={callAnimation} loop={false} autoplay={true} onEnterFrame={frameEnter} lottieRef={submitAnimation} />
                            </div>
                            <div className="ok">
                                <Lottie animationData={okAnimation} loop={false} autoplay={false} lottieRef={successAnimation} />
                            </div>
                        </div>
                    </div>
                    {formSuccess && <p className="text-center">Děkujeme, ozveme se Vám hned jak to bude možné.</p>}
                    {!formSuccess && <p className="text-center out-of-service-info" dangerouslySetInnerHTML={{ __html: outOfServiceInfo() }} />}
                    {!formSuccess && <input onKeyDown={handleKeyDown} onChange={handlePhoneNumberChange} value={phoneNumber} className="form-control form-input form-input_auth mb-1" name="name" id="name" type="text" placeholder="602 602 602" />}
                    {formError && <small className="error">Nesprávné číslo. Prosím napište číslo ve tvaru: 602 602 602</small>}
                </div>
            </div>
            {!formSuccess && <SubmitButton onClick={handleFormSubmit} preventInput={formLoading} />}
        </>
    )
}

export default OutOfServiceForm
