import React from 'react'
import ReactDOM from 'react-dom/client'
import Chat from './components/Chat'
import moment from 'moment/moment'
import '../styles/app.scss'
import * as Sentry from '@sentry/react'
import { mode } from '../../../../FrontendBundle/Resources/Assets/js/config'

if (process.env.APP_ENV === mode.PRODUCTION) {
    Sentry.init({
        dsn: process.env.SENTRY_DSN_CHAT,
        integrations: [Sentry.browserTracingIntegration()],
        // Performance Monitoring
        tracesSampleRate: 0.2, //  Capture 20% of the transactions
        // Preventing irrelevant error reports from pushAlert script, since sentry runs the script in its own browser interface, which lacks window.Notification.
        beforeSend(event) {
            if (event.exception) {
                const error = event.exception.values[0]
                if (error.type === 'ReferenceError' && error.value.includes('Notification is not defined')) {
                    return null
                }
            }
            return event
        },
    })
}

moment.locale('cs')

const root = ReactDOM.createRoot(document.getElementById('chat-app'))

root.render(<Chat />)
