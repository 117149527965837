import React, { useCallback, useState } from 'react'
import EventBus from './Shared/EventBus'
import SubmitButton from './ChatMessage/SubmitButton'
import { throttle } from 'lodash'

function ChatMessageBar(props) {
    const { preventInput } = props
    const [text, setText] = useState('')

    const handleKeyDown = (event) => {
        if (event.which === 13 && !event.shiftKey) {
            event.preventDefault()
            sendMessage()
        }
    }

    const handleKeyUp = useCallback(
        throttle(
            () => {
                EventBus.emit('start-client-typing')
            },
            1800,
            { trailing: false }
        ),
        []
    )

    const handleChange = (event) => {
        event.preventDefault()
        setText(event.target.value)
    }

    const sendMessage = () => {
        if (text === '' || preventInput) {
            return
        }

        EventBus.emit('send-message', text)
        setText('')
    }

    return (
        <div className="chat-message-bar">
            <div className="message-bar__divider"></div>
            <div className="message-bar-wrapper">
                <textarea value={text} onChange={handleChange} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} className="message-bar__text-input" placeholder="Napište cokoliv..." />
                <SubmitButton onClick={sendMessage} preventInput={preventInput} />
            </div>
        </div>
    )
}

export default ChatMessageBar
