import React, { useEffect, useState } from 'react'
import ChatBody from './ChatBody'
import ChatHeader from './ChatHeader'
import ChatMessageBar from './MessageBar'
import UseChat from './Modules/Chat/UseChat'
import EventBus from './Shared/EventBus'
import Form from './Forms/Form'
import messageBubbleIcon from '../../images/message-bubble.svg'
import HelpSuggestion from './HelpSuggestion'
import RunningConversationNotification from './RunningConversationNotification'
import NewMessageNotification from './NewMessageNotification'
import { settings } from '../../../../../FrontendBundle/Resources/Assets/js/config'

function Chat() {
    const [loaded, setIsLoaded] = useState(false)
    let chat = new UseChat()

    useEffect(() => {
        EventBus.on('chat-deinit', () => {
            chat.deinit()
        })

        chat.init().then(() => setIsLoaded(true))
    }, [])

    const activateChat = async () => {
        if (!chat.initialized) {
            await chat.init()
        }

        EventBus.emit('chat-open')
    }

    return (
        <div>
            {chat && loaded === true && (
                <div className={`chat ${chat.active ? 'active' : ''}`}>
                    <div className="chat-window">
                        <Form feedbackMessage={chat.feedbackSubmitMessage} activeForm={chat.activeForm} chatUuid={chat.chatUuid} />
                        <div className={`chat-communication ${chat.activeForm ? 'hidden' : ''}`}>
                            <ChatHeader />
                            <ChatBody messages={chat.messages} serverTyping={chat.serverTyping} />
                            <ChatMessageBar preventInput={chat.preventInput} />
                        </div>
                    </div>
                    <div onClick={activateChat} className="chat-head">
                        {!chat.active && chat.chatUuid !== null && chat.newMessage && (
                            <div>
                                <div className="chat-notification"></div>
                                <div className="chat-notification-static"></div>
                            </div>
                        )}
                        <img src={settings.assets() + messageBubbleIcon} alt="open chat icon" />
                    </div>
                    {!chat.active && chat.chatUuid === null && <HelpSuggestion activateChat={activateChat} />}
                    {!chat.active && chat.chatUuid !== null && !chat.newMessage && chat.runningConversationSuggestion && <RunningConversationNotification activateChat={activateChat} />}
                    {!chat.active && chat.chatUuid !== null && chat.newMessage && <NewMessageNotification activateChat={activateChat} />}
                </div>
            )}
        </div>
    )
}

export default Chat
