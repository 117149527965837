import React, { useEffect, useState } from 'react'
import closeIcon from '../../images/close_black.svg'
import EventBus from './Shared/EventBus'
import { settings } from '../../../../../FrontendBundle/Resources/Assets/js/config'

function RunningConversationNotification(props) {
    const { activateChat } = props
    const [isVisible, setIsVisible] = useState(true)

    useEffect(() => {
        setTimeout(() => setIsVisible(false), 5000)
    }, [])

    const closeChat = () => {
        EventBus.emit('form-open', 'resolveChat')
        activateChat()
    }

    return (
        <div>
            {isVisible && (
                <div className="message-suggestion">
                    <div className="message-suggestion-wrapper">
                        <span className="message-suggestion-text">Máte probíhající konverzaci!</span>
                        <div className="d-flex flex-row">
                            <button className="btn message-suggestion-button" onClick={activateChat}>
                                Pokračovat
                            </button>
                            <button className="btn message-suggestion-button ml-2 bg-danger" onClick={closeChat}>
                                Ukončit
                            </button>
                        </div>
                        <img className="message-suggestion-close-button" src={settings.assets() + closeIcon} alt="chevron" onClick={() => setIsVisible(false)} />
                    </div>
                </div>
            )}
        </div>
    )
}

export default RunningConversationNotification
