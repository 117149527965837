import React, { useRef } from 'react'
import sendIcon from '../../../images/x-circle.svg'
import EventBus from '../Shared/EventBus'
import { settings } from '../../../../../../FrontendBundle/Resources/Assets/js/config'
import { ratingFeedback } from '../Shared/enums'

function ResolveChatForm({ feedbackMessage }) {
    const submitAnimation = useRef(null)

    const handleFormSubmit = () => {
        submitAnimation.current.play()
    }

    const frameEnter = (data) => {
        const frame = Math.floor(data.currentTime * 100) / 100

        if (frame > 56 && frame < 56.75) submitAnimation.current.pause()
    }

    const handleResolveChat = () => {
        EventBus.emit('chat-resolve')
    }

    const handleCancel = () => {
        EventBus.emit('form-close')
    }

    const handleRatingFeedback = (event, feedback) => {
        event.preventDefault()
        EventBus.emit('send-rating-feedback', feedback)
    }

    return (
        <form>
            <div className="form__content">
                <div className="content-wrapper">
                    <div className="image-wrapper">
                        <div>
                            <img
                                src={settings.assets() + sendIcon}
                                alt="send-icon"
                                style={{
                                    width: '50px',
                                }}
                            />
                        </div>
                    </div>

                    {feedbackMessage ? (
                        <p className="text-center mb-0">{feedbackMessage}</p>
                    ) : (
                        <>
                            <p>Děkujeme, že jste využili naši zákaznickou podporu. Jak byste ohodnotili zkušenost s naším chatem?</p>
                            <div className="feedback-button-wrapper">
                                <button
                                    onClick={(event) => {
                                        handleRatingFeedback(event, ratingFeedback.positive)
                                    }}
                                    className="feedback-button">
                                    <div className="feedback-button__icon">👍</div>
                                </button>
                                <button
                                    onClick={(event) => {
                                        handleRatingFeedback(event, ratingFeedback.negative)
                                    }}
                                    className="feedback-button">
                                    <div className="feedback-button__icon">👎</div>
                                </button>
                            </div>
                        </>
                    )}
                    <hr style={{ backgroundColor: '#e8e8e8', width: '100%' }} />
                    <div>
                        <p className="text-center">Opravdu chcete ukončit chat?</p>
                        <div className="buttons-bar">
                            <button onClick={handleResolveChat} className="btn form-button submit" type="button">
                                Ano
                            </button>
                            <button onClick={handleCancel} className="btn form-button cancel" type="button">
                                Ne
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default ResolveChatForm
