import React from 'react'

function TypingMessage(props) {
    const { active } = props

    return (
        <div className="chat-message typing d-flex w-full">
            <div className={`content ${active ? 'active' : ''}`}>
                <span>{'\u25CF'}</span>
                <span>{'\u25CF'}</span>
                <span>{'\u25CF'}</span>
            </div>
        </div>
    )
}

export default TypingMessage
