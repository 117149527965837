import React from 'react'
import Lottie from 'lottie-react'
import WaitingAnimation from '../../../images/waiting.json'
import sendIcon from '../../../images/send-icon.svg'
import { settings } from '../../../../../../FrontendBundle/Resources/Assets/js/config'

function SubmitButton(props) {
    const { onClick, preventInput } = props

    return (
        <button onClick={onClick} className={`send-message-button ${preventInput === true ? 'disabled' : ''}`} type="button">
            <div className="send-icon-wrapper">{preventInput === true ? <Lottie animationData={WaitingAnimation} loop autoplay /> : <img src={settings.assets() + sendIcon} alt="send-icon" />}</div>
        </button>
    )
}

export default SubmitButton
