import React from 'react'

function IdCardInput(props) {
    const { onSubmit, onChange, value } = props

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            onSubmit()
        }
    }

    const handleIdentityCardNumberChange = (event) => {
        let suggested = event.target.value

        if (suggested.length > 9) {
            suggested = suggested.slice(0, 9)
        }

        onChange(suggested)
    }

    return <input onKeyDown={handleKeyDown} onChange={handleIdentityCardNumberChange} value={value} className="form-control form-input form-input_auth mb-1" name="identityCard" id="identityCard" type="text" placeholder="123456" />
}

export default IdCardInput
