import React from 'react'
import SenderMessage from './SenderMessage'
import ServerMessage from './ServerMessage'
import SystemMessage from './SystemMessage'
import ButtonMessage from './ButtonMessage'

function Message(props) {
    const { text, type, link, linkLabel, dateTime } = props.message
    const { stack } = props

    const formattedDatetime = dateTime.locale('cs').calendar()

    const messageTypes = {
        client: <SenderMessage text={text} date={formattedDatetime} stack={stack} />,
        operator: <ServerMessage text={text} date={formattedDatetime} stack={stack} />,
        chatbot: <ServerMessage text={text} date={formattedDatetime} stack={stack} />,
        system: <SystemMessage text={text} />,
        link: <ButtonMessage text={text} link={link} linkLabel={linkLabel} />,
    }

    return <div>{messageTypes[type]}</div>
}

export default Message
