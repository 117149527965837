import React from 'react'
import EventBus from '../Shared/EventBus'
import AuthenticationForm from './AuthForm'
import OutOfServiceForm from './OutOfServiceForm'
import ResolveChatForm from './ResolveChatForm'
import closeIcon from '../../../images/close-button.svg'
import { settings } from '../../../../../../FrontendBundle/Resources/Assets/js/config'

function Form(props) {
    const { activeForm, chatUuid, feedbackMessage } = props

    const closeForm = () => {
        if (allowCloseForm()) {
            EventBus.emit('form-close')
        } else {
            if (closeFormOutOfService()) {
                EventBus.emit('chat-resolve')
            }
            EventBus.emit('chat-close')
        }
    }

    const onFormClose = () => {
        EventBus.emit('send-feedback', { action: activeForm, result: false })
    }

    const allowCloseForm = () => {
        return ['clientAuthenticate', 'resolveChat'].includes(activeForm)
    }

    const closeFormOutOfService = () => {
        return ['outOfService'].includes(activeForm)
    }

    return (
        <div className={`chat-form ${activeForm ? 'form-open' : ''}`}>
            <div className="close-form-bar">
                <img src={settings.assets() + closeIcon} onClick={closeForm} alt="close form icon" className="close-form-icon" />
            </div>

            {activeForm === 'resolveChat' && <ResolveChatForm feedbackMessage={feedbackMessage} />}
            {activeForm === 'outOfService' && <OutOfServiceForm />}
            {activeForm === 'clientAuthenticate' && <AuthenticationForm chatUuid={chatUuid} closeForm={onFormClose} />}
        </div>
    )
}

export default Form
