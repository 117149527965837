import React, { useEffect, useState } from 'react'
import closeIcon from '../../images/close_black.svg'
import { settings } from '../../../../../FrontendBundle/Resources/Assets/js/config'

function NewMessageNotification(props) {
    const { activateChat } = props
    const [isVisible, setIsVisible] = useState(true)

    useEffect(() => {
        setTimeout(() => setIsVisible(false), 20000)
    }, [])

    return (
        <>
            {isVisible && (
                <div className="message-suggestion">
                    <div className="message-suggestion-wrapper">
                        <span className="message-suggestion-text">Máte novou zprávu!</span>
                        <button className="btn message-suggestion-button" onClick={activateChat}>
                            Zobrazit novou zprávu
                        </button>
                        <img className="message-suggestion-close-button" src={settings.assets() + closeIcon} alt="chevron" onClick={() => setIsVisible(false)} />
                    </div>
                </div>
            )}
        </>
    )
}

export default NewMessageNotification
