const newEventBus = () => {
    const listeners = {}
    const nextListenerKey = (key => () => ++key)(0)

    const makeNewListener = (handler) => ({ key: nextListenerKey(), handler })

    const addListener = (newListener, eventName) => {
        if (!listeners[eventName]) listeners[eventName] = []

        listeners[eventName].push(newListener)
    }

    const remove = (eventName) => {
        if (!listeners[eventName]) return
        delete listeners[eventName]
    }

    const on = (eventName, handler) => {
        const newListener = makeNewListener(handler)

        addListener(newListener, eventName)

        return () => remove(newListener, eventName)
    }

    const emit = (eventName, payload) => {
        const eventListeners = listeners[eventName] ?? []
        eventListeners.forEach((eventListener) => eventListener.handler(payload))
    }

    return {
        on,
        emit,
        remove,
    }
}

export default newEventBus()
