import React from 'react'

function PincodeInput(props) {
    const { onSubmit, onChange, value } = props

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            onSubmit()
        }

        if (/^[a-zA-Z\s`~!@#$%^&*()_={}\[\]:;"'<>,?\\|+ľščťžýáíé='úä§ňô§\-.]*$/u.test(event.key) && !event.metaKey && ![8, 9, 37, 38, 39, 40, 46].includes(event.keyCode)) {
            event.preventDefault()
        }
    }

    const handlePinChange = (event) => {
        let suggested = event.target.value

        suggested = suggested.replace(/[^0-9]/, '')

        if (suggested.length > 4) {
            suggested = suggested.slice(0, 4)
        }

        onChange(suggested)
    }

    return <input autoFocus onKeyDown={handleKeyDown} onChange={handlePinChange} value={value} className="pincode-input form-control form-input form-input_auth mb-1" name="name" id="name" type="text" placeholder="9999" />
}

export default PincodeInput
