import React from 'react'

function SystemMessage(props) {
    const { text } = props

    return (
        <div className="chat-message chat-message_system">
            <div className="content" dangerouslySetInnerHTML={{ __html: text }} />
        </div>
    )
}

export default SystemMessage
