import React from 'react'

function SenderMessage(props) {
    const { text, date, stack } = props

    return (
        <div className="chat-message chat-message_sender">
            {!stack && <div className="timestamp">{date}</div>}
            <div className="content">{text}</div>
        </div>
    )
}

export default SenderMessage
