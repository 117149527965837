import IncomingMessage from './IncomingMessage'
import EventBus from '../../../Shared/EventBus'

const Socket = () => {
    let websocket = null
    let connected = false
    let uuid = null

    const connect = (chatUuid, customerHash = null) => {
        websocket = new WebSocket(chatWebsocketAddress)

        websocket.onerror = () => {
            EventBus.emit('form-open', 'outOfService')
        }

        websocket.onopen = () => {
            EventBus.emit('form-close', 'outOfService')

            websocket.send(
                JSON.stringify({
                    type: 'initialize',
                    uuid: chatUuid,
                    chash: customerHash,
                })
            )

            uuid = chatUuid
            connected = true
        }

        websocket.onclose = () => {
            if (connected) {
                connected = false
                setTimeout(reconnect, 1000)
            }
        }

        websocket.onmessage = (event) => {
            receive(event.data)
        }
    }

    const setUuid = (chatUuid) => {
        uuid = chatUuid
    }

    const disconnect = () => {
        websocket.onclose = () => {}
        connected = false
        websocket.close()
        uuid = null
        websocket = null
    }

    const start = (chatUuid = null, customerHash = null) => {
        connect(chatUuid, customerHash)

        window.addEventListener('beforeunload', () => {
            EventBus.emit('form-close')
        })
    }

    const reconnect = () => {
        if (!connected) {
            connect(uuid)
            setTimeout(reconnect, 10000)
        }
    }

    const send = (text) => {
        websocket.send(
            JSON.stringify({
                type: 'client',
                text: text,
            })
        )
    }

    const sendTyping = () => {
        websocket.send(
            JSON.stringify({
                type: 'typing',
                chatUuid: uuid,
            })
        )
    }

    const sendFeedback = (action, result) => {
        websocket.send(
            JSON.stringify({
                type: 'feedback',
                chatUuid: uuid,
                action: action,
                result: result,
            })
        )
    }

    const resolve = () => {
        websocket.send(
            JSON.stringify({
                type: 'action',
                action: 'clientResolveChat',
            })
        )
    }

    const receive = (message) => {
        message = new IncomingMessage(JSON.parse(message))

        switch (message.type) {
            case IncomingMessage.TYPE_ACTION:
                EventBus.emit('action-message', message)
                break
            case IncomingMessage.TYPE_TYPING:
                EventBus.emit('typing-message', message)
                break
            case IncomingMessage.TYPE_INITIAL:
                EventBus.emit('initialize-message', message)
                break
            case IncomingMessage.TYPE_CLIENT:
                EventBus.emit('client-message', message)
                break
            case IncomingMessage.TYPE_LINK:
                EventBus.emit('client-message', message)
                break
            case IncomingMessage.TYPE_OPERATOR:
                EventBus.emit('operator-message', message)
                break
            case IncomingMessage.TYPE_CHATBOT:
                EventBus.emit('operator-message', message)
                break
            case IncomingMessage.TYPE_SYSTEM:
                EventBus.emit('system-message', message)
                break
        }
    }

    return {
        start,
        disconnect,
        send,
        sendFeedback,
        sendTyping,
        resolve,
        setUuid,
    }
}

export default Socket()
