import React, { useEffect, useState } from 'react'
import closeIcon from '../../images/close_black.svg'
import sendIcon from '../../images/send-icon.svg'
import { setCookie, getCookie } from './Shared/Helpers'
import { settings } from '../../../../../FrontendBundle/Resources/Assets/js/config'

function HelpSuggestion(props) {
    const { activateChat } = props
    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        if (getCookie('helpSuggestion')) {
            return
        }

        let date = new Date()
        date.setTime(date.getTime() + 1.3 * 60 * 60 * 1000)
        setCookie('helpSuggestion', 1, date.toUTCString())

        setTimeout(() => {
            setIsVisible(true)
            setTimeout(() => setIsVisible(false), 10000)
        }, 6000)
    }, [])

    return (
        <div>
            {isVisible && (
                <div className="help-suggestion">
                    <div className="help-suggestion-wrapper">
                        <span className="help-suggestion-text" style={{ fontWeight: '600' }}>
                            Vítejte!
                            <div className="hand" style={{ transform: 'translate3d(0, 0, 0) scale(1.0) rotate(0)' }}>
                                👋🏼
                            </div>
                        </span>
                        <span className="help-suggestion-text">Máte konkrétní otázku nebo potřebujete pomoci? S čím vám můžeme poradit?</span>
                        <button className="btn help-suggestion-button" onClick={activateChat}>
                            Zahájit konverzaci&nbsp;
                            <img src={settings.assets() + sendIcon} alt="chevron" />
                        </button>
                        <img className="help-suggestion-close-button" src={settings.assets() + closeIcon} alt="chevron" onClick={() => setIsVisible(false)} />
                    </div>
                </div>
            )}
        </div>
    )
}

export default HelpSuggestion
