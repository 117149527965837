import React, { useEffect, useRef } from 'react'
import Message from './ChatMessage/Message'
import TypingMessage from './ChatMessage/TypingMessage'

function ChatBody(props) {
    const body = useRef(null)
    const { messages, serverTyping } = props

    const scrollDown = () => {
        body.current.scrollTop = body.current.scrollHeight - body.current.clientHeight - (serverTyping ? 0 : 35)
    }

    const handleScroll = (event, enabled) => {
        event.preventDefault()
        if (enabled) {
            const maxScroll = body.current.scrollHeight - body.current.clientHeight - 35

            if (body.current.scrollTop > maxScroll) {
                event.preventDefault()
                body.current.style.overflowY = 'hidden'
                body.current.style.scrollBehavior = 'unset'
                body.current.scrollTop = maxScroll

                setTimeout(() => {
                    body.current.style.overflowY = 'scroll'
                    body.current.style.scrollBehavior = 'smooth'
                }, 500)
            }
        }
    }

    useEffect(() => {
        const triggerScrollLimit = body.current.scrollTop < 400 || body.current.scrollTop >= body.current.scrollHeight - body.current.clientHeight - 400
        triggerScrollLimit && scrollDown()
    }, [messages, serverTyping])

    return (
        <div ref={body} onScroll={(event) => handleScroll(event, !serverTyping)} className="chat-body">
            <div className="messages">
                {messages.map((message, key) => {
                    const isStack = messages[key - 1]?.type === message.type

                    return <Message key={key} message={message} stack={isStack} />
                })}
                <TypingMessage active={serverTyping} />
            </div>
        </div>
    )
}

export default ChatBody
