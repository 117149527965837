import React, { useState } from 'react'
import Popover from '../Popover'

function ButtonMessage(props) {
    const { text, link, linkLabel } = props
    const [popoverActive, setPopoverActive] = useState(false)

    const handleRedirect = () => {
        window.open(link)
    }

    const showPopover = () => {
        setPopoverActive(true)
    }

    const hidePopover = () => {
        setPopoverActive(false)
    }

    return (
        <div className="chat-message chat-message_button">
            <div className="content">
                {text}
                <div className="message-button__wrapper" onMouseEnter={showPopover} onMouseLeave={hidePopover}>
                    <Popover showPopover={popoverActive} text={link} />
                    <button onClick={handleRedirect} className="btn-redirect">
                        {linkLabel ?? 'Klikněte pro přesměrování'}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ButtonMessage
