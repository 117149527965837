import moment from 'moment'

class IncomingMessage {
    static get TYPE_INITIAL() {
        return 'initialize'
    }
    static get TYPE_TYPING() {
        return 'typing'
    }
    static get TYPE_CLIENT() {
        return 'client'
    }
    static get TYPE_OPERATOR() {
        return 'operator'
    }
    static get TYPE_CHATBOT() {
        return 'chatbot'
    }
    static get TYPE_SYSTEM() {
        return 'system'
    }
    static get TYPE_ACTION() {
        return 'action'
    }
    static get TYPE_LINK() {
        return 'link'
    }

    constructor(data) {
        this.type = data.type
        this.chatId = data.chatId ?? null
        this.uuid = data.chatUuid ?? null
        this.text = data.text ?? null
        this.link = data.link ?? null
        this.linkLabel = data.linkLabel ?? null
        this.action = data.action ?? null
        this.feedback = data.feedback ?? null
        this.dateTime = data.dateTime?.timestamp ? moment.unix(data.dateTime?.timestamp) : moment(data.date_time)
        this.preventInput = data.preventInput ?? false
    }
}

export default IncomingMessage
