// TODO přepsat na cookie knihovnu!!!

export const setCookie = (name, value, date) => {
    document.cookie = `${name}=${value}; expires=${date}; path=/`
}

export const getCookie = (name) => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)

    if (parts.length === 2) {
        return parts.pop().split(';').shift()
    }
}

export const removeCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;  path=/`
}

export const SetSuggestionHelperCookie = () => {
    let date = new Date()
    date.setTime(date.getTime() + 4 * 60 * 60 * 1000)
    setCookie('helpSuggestion', 1, date.toUTCString())
}
